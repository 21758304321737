
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Sidebar from '@/components/left-sidebar.vue'; // @ is an alias to /src
import RSidebar from '@/components/right-sidebar.vue'; // @ is an alias to /src
// import HRMService from "../../../services/hrm-service";
// import hrmService from '../../../services/hrm-service';
import AssetsServices from '@/services/assets-services';
import PopUp from "@/components/PopUp.vue";
import utils from "@/utils/utils";

@Component({
  components: {
    Header,
    Sidebar,
    RSidebar,
    PopUp,
  },
})
export default class CreateAssets extends Vue {
  @Prop({ type: Object, default: () => { } }) updatePayload!: any;
  @Prop({ default: false }) forUpdate!: boolean;
  @Prop({ type: Function, default: () => { } }) retrieve!: Function;
  private data: any[] = [];
  private currentItem: any = null;
  private currentIndex: number = -1;
  private title: string = "";
  private rows = 100;
  private currentPage = 1
  userId = 0;
  public fields = [
    {
      key: 'code',
      sortable: true
    },
    {
      key: 'title',
      sortable: true
    },
    {
      key: 'assets_class',
      sortable: false
    },
    {
      key: 'assets_type',
      sortable: false
    },
    {
      key: 'registration',
      sortable: true,
      // variant: 'danger'
    },
    {
      key: 'model',
      sortable: true,
      // variant: 'danger'
    },
    {
      key: 'division',
      sortable: true,
      // variant: 'danger'
    },
    {
      key: 'purchase_date',
      sortable: true,
      // variant: 'danger'
    },
    {
      key: 'purchase_amout',
      sortable: true,
      // variant: 'danger'
    },
    {
      key: 'comments',
      sortable: true,
      // variant: 'danger'
    },
  ];
  public payload: any = {}
  public items = []
  error: any = "";

  // For Modal
  modalShow = false;
  modalMessage: string[] = [];
  modalSimpleMessage: string = "";
  modalType = "";
  saveData = true;

  closeModal() {
    this.modalShow = false;
    this.modalMessage = [];
    this.modalSimpleMessage = "";
    this.modalType = "";
  }

  showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
    this.modalShow = true;
    this.modalMessage = array;
    this.modalType = type;
    this.modalSimpleMessage = message
  }
  // modal end
  mounted() {
    this.getData();
  }
  create() {
    const localUser = localStorage.getItem("user");
    if (localUser !== null) {
      const userObject = JSON.parse(localUser);
      this.userId = userObject.id;
    }
    this.payload['created_by'] = this.userId
    this.error = ""
    const error_list = utils.getEmptyKeys(this.payload, ['code', 'title', 'asset_class', 'asset_type', 'registration', 'model', 'division', 'purchase_date', 'purchase_amount', 'comments'])
    if (error_list.length > 0) {
      this.error = utils.generateErrorMessage(error_list);
    }
    else {
      if (!this.forUpdate) {
        AssetsServices.createAssets(this.payload).then((response) => {
          if (response.status == 201) {
            this.showModal("Created Successfully", [], "success");
            this.payload = {};
            this.retrieve();
            this.error = "";
          }
          else {
            this.showModal("", response.data, "errorArray");
          }
        }).catch((err) => {
          console.log('Unexpected error:', err);
          this.showModal('An unexpected error occurred', [], "error");
        })
      } else {
        console.log('update')
        AssetsServices.updateAssets(this.payload, this.payload.id).then((response) => {
          if (response.status == 200) {
            this.showModal("Updated Successfully", [], "success");
            this.payload = {};
            this.retrieve();
            this.error = "";
          }
          else {
            this.showModal("", response.data, "errorArray");
          }
        }).catch((err) => {
          console.log('Unexpected error:', err);
          this.showModal('An unexpected error occurred', [], "error");
        })
      }

    }
  }
  options: any = []
  getData() {
    AssetsServices.getAssetClass().then((response) => {
      this.options = response.data
    }).catch((err) => {
      console.log(err)

    })
  }
  // retrieve() {
  //   HRMService.getAll()
  //     .then((response) => {
  //       this.items = response.data;
  //       // console.log(response.data);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  // refreshList() {
  //   this.retrieve();
  //   this.currentItem = null;
  //   this.currentIndex = -1;
  // }

  // setActiveItem(data: any, index: number) {
  //   this.currentItem = data;
  //   this.currentIndex = index;
  // }

  // removeAll() {
  //   hrmService.deleteAll()
  //     .then((response) => {
  //       console.log(response.data);
  //       this.refreshList();
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  // searchTitle() {
  //   hrmService.findByTitle(this.title)
  //     .then((response) => {
  //       this.data = response.data;
  //       console.log(response.data);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  // mounted() {
  //   this.retrieve();
  // }
  reset() {
    this.payload = {};
    this.error = "";
  }
  @Watch("updatePayload")
  onModalShowChanged(newValue: boolean, oldValue: boolean) {
    this.payload = this.updatePayload;
  }
}
